import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../utils/axios";
import { useLoginAuth } from "../contexts/authcontext";
import "./loginauth.css";
import logger from "../utils/logger";

const LoginAuth = () => {
    const { login, token } = useLoginAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    // Rediriger si déjà connecté
    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    }, [token, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            await login(formData.email, formData.password);
            setRetryCount(0); // Réinitialiser le compteur en cas de succès
        } catch (error) {
            logger.error('Login error:', error);
            
            if (error.code === 'ERR_NETWORK') {
                setError('Impossible de se connecter au serveur. Vérifiez votre connexion internet.');
                
                // Tentative automatique de reconnexion
                if (retryCount < 3) {
                    setError('Tentative de reconnexion...');
                    setRetryCount(prev => prev + 1);
                    setTimeout(() => {
                        handleSubmit(e);
                    }, 2000 * retryCount);
                    return;
                }
            } else if (error.response?.status === 401) {
                setError('Email ou mot de passe incorrect');
            } else {
                setError('Une erreur est survenue. Veuillez réessayer plus tard.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="authContainer">
            <div className="authCard">
                <h2 className="authTitle">Connexion Admin</h2>
                {error && (
                    <div className={`error-message ${error.includes('Tentative') ? 'warning' : 'error'}`}>
                        {error}
                        {retryCount >= 3 && (
                            <button 
                                onClick={() => {
                                    setRetryCount(0);
                                    setError('');
                                }}
                                className="retry-button"
                            >
                                Réessayer
                            </button>
                        )}
                    </div>
                )}
                <form onSubmit={handleSubmit} className="auth-form">
                    <div className="formGroup">
                        <label className="label">Email</label>
                        <input 
                            type="email" 
                            className="input"
                            placeholder="Entrez votre email"
                            value={formData.email} 
                            onChange={(e) => setFormData({...formData, email: e.target.value})} 
                            required 
                        />
                    </div>
                    <div className="formGroup">
                        <label className="label">Mot de passe</label>
                        <input 
                            type="password" 
                            className="input"
                            placeholder="Entrez votre mot de passe"
                            value={formData.password} 
                            onChange={(e) => setFormData({...formData, password: e.target.value})} 
                            required 
                        />
                    </div>
                    <button 
                        type="submit" 
                        className={`primaryButton ${loading ? 'loading' : ''}`} 
                        disabled={loading}
                    >
                        {loading ? 'Connexion...' : 'Se connecter'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginAuth;
