import React from 'react';
import '../styles/home.css';

const Home = () => {
  return (
    <div className="home-container">
      <div className="hero-section">
        <h1>Bienvenue sur notre Plateforme</h1>
        <p className="subtitle">Découvrez nos services innovants</p>
        <button className="cta-button">Commencer</button>
      </div>
      <div className="features-section">
        <div className="feature-card">
          <h3>Simple</h3>
          <p>Interface intuitive et facile à utiliser</p>
        </div>
        <div className="feature-card">
          <h3>Rapide</h3>
          <p>Performance optimisée pour une expérience fluide</p>
        </div>
        <div className="feature-card">
          <h3>Sécurisé</h3>
          <p>Protection avancée de vos données</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
