import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import '../styles/SiteList.css';

const SiteList = () => {
  const navigate = useNavigate();
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortBy, setSortBy] = useState('name');
  const [newSite, setNewSite] = useState({ name: '', url: '', description: '' });
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    try {
      const response = await axiosInstance.get('/api/monitoring/sites');
      setSites(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des sites:', error);
      setLoading(false);
    }
  };

  const handleAddSite = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/api/monitoring/sites', newSite);
      setNewSite({ name: '', url: '', description: '' });
      setShowAddForm(false);
      fetchSites();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du site:', error);
    }
  };

  const handleSiteClick = (siteId) => {
    navigate(`/monitoring/sites/${siteId}`);
  };

  const filteredSites = sites
    .filter(site => {
      const matchesSearch = site.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          site.url.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          (site.description && site.description.toLowerCase().includes(searchTerm.toLowerCase()));
      const matchesStatus = statusFilter === 'all' || site.status === statusFilter;
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'status':
          return a.status.localeCompare(b.status);
        case 'uptime':
          return b.uptime - a.uptime;
        case 'responseTime':
          return a.responseTime - b.responseTime;
        default:
          return 0;
      }
    });

  if (loading) {
    return <div className="loading">Chargement...</div>;
  }

  return (
    <div className="site-list-container">
      <div className="list-header">
        <h1>Sites Monitorés</h1>
        <button 
          className="add-site-button primary"
          onClick={() => setShowAddForm(!showAddForm)}
        >
          {showAddForm ? '❌ Annuler' : '➕ Ajouter un site'}
        </button>
      </div>

      {showAddForm && (
        <div className="add-site-section">
          <div className="setup-instructions">
            <h3>🔍 Comment préparer votre site pour le monitoring ?</h3>
            <ol>
              <li>
                <strong>Créez le fichier d'autorisation :</strong>
                <pre>/.well-known/monitoring-allowed</pre>
                <p>Sur votre serveur web, créez le dossier <code>.well-known</code> à la racine et le fichier <code>monitoring-allowed</code> dedans.</p>
              </li>
              <li>
                <strong>Ajoutez votre token de monitoring :</strong>
                <pre>MONITOR_TOKEN=votre_token_de_monitoring</pre>
                <p>Copiez votre token depuis la section "Token de Monitoring" et ajoutez-le dans le fichier.</p>
              </li>
              <li>
                <strong>Vérifiez l'accès :</strong>
                <p>Le fichier doit être accessible via : <code>https://votre-site.com/.well-known/monitoring-allowed</code></p>
              </li>
            </ol>
          </div>

          <form className="add-site-form" onSubmit={handleAddSite}>
            <h3>📝 Informations du site</h3>
            <div className="form-group">
              <label>
                Nom du site :
                <input
                  type="text"
                  placeholder="Ex: Mon Blog Personnel"
                  value={newSite.name}
                  onChange={(e) => setNewSite({ ...newSite, name: e.target.value })}
                  required
                />
              </label>
              <label>
                URL du site :
                <input
                  type="url"
                  placeholder="https://monsite.com"
                  value={newSite.url}
                  onChange={(e) => setNewSite({ ...newSite, url: e.target.value })}
                  required
                />
              </label>
              <label>
                Description (optionnelle) :
                <input
                  type="text"
                  placeholder="Une brève description de votre site"
                  value={newSite.description}
                  onChange={(e) => setNewSite({ ...newSite, description: e.target.value })}
                />
              </label>
            </div>
            <div className="form-actions">
              <button type="button" className="cancel-btn" onClick={() => setShowAddForm(false)}>Annuler</button>
              <button type="submit" className="submit-btn">Ajouter le site</button>
            </div>
          </form>
        </div>
      )}

      <div className="filters">
        <div className="search-box">
          <input
            type="text"
            placeholder="Rechercher un site..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="filter-group">
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">Tous les statuts</option>
            <option value="up">En ligne</option>
            <option value="down">Hors ligne</option>
            <option value="pending">En attente</option>
          </select>

          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="name">Trier par nom</option>
            <option value="status">Trier par statut</option>
            <option value="uptime">Trier par disponibilité</option>
            <option value="responseTime">Trier par temps de réponse</option>
          </select>
        </div>
      </div>

      <div className="sites-grid">
        {filteredSites.length === 0 ? (
          <p className="no-results">Aucun site trouvé</p>
        ) : (
          filteredSites.map(site => (
            <div
              key={site._id}
              className="site-card"
              onClick={() => handleSiteClick(site._id)}
            >
              <div className="card-header">
                <h3>{site.name}</h3>
                <div className={`status-badge ${site.status}`}>
                  {site.status === 'up' ? 'En ligne' : site.status === 'down' ? 'Hors ligne' : 'En attente'}
                </div>
              </div>
              
              <p className="site-url">{site.url}</p>
              {site.description && (
                <p className="site-description">{site.description}</p>
              )}

              <div className="site-metrics">
                <div className="metric">
                  <span className="label">Disponibilité</span>
                  <span className="value">{site.uptime.toFixed(2)}%</span>
                </div>
                <div className="metric">
                  <span className="label">Temps de réponse</span>
                  <span className="value">{site.responseTime}ms</span>
                </div>
                <div className="metric">
                  <span className="label">Dernier check</span>
                  <span className="value">{new Date(site.lastCheck).toLocaleString()}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SiteList; 