import axios from 'axios';

const BASE_URL = 'https://api.sandbox.logandelmairedev.com';

// Routes qui ne nécessitent pas de token
const PUBLIC_ROUTES = [
  '/api/monitoring/auth/check-admin',
  '/api/monitoring/auth/login',
  '/api/monitoring/auth/register'
];

// Instance Axios pour toutes les routes monitoring
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Ajouter le token aux requêtes authentifiées
api.interceptors.request.use((config) => {
  const isPublicRoute = PUBLIC_ROUTES.some(route => config.url?.includes(route));
  
  if (!isPublicRoute) {
    const token = localStorage.getItem('monitoringAuthToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Token ajouté à la requête:', config.url);
    } else {
      console.warn('Token d\'authentification manquant pour la requête:', config.url);
      // Rediriger vers la page de connexion si le token est manquant
      window.location.href = '/monitoring/login';
      return Promise.reject('Token manquant');
    }
  }

  // Ajouter un timestamp pour éviter le cache
  const separator = config.url.includes('?') ? '&' : '?';
  config.url = `${config.url}${separator}_t=${Date.now()}`;
  
  return config;
}, (error) => {
  console.error('Erreur dans l\'intercepteur de requête:', error);
  return Promise.reject(error);
});

// Intercepteur pour gérer les réponses et les erreurs
api.interceptors.response.use(
  response => response,
  error => {
    // Gérer les erreurs d'authentification
    if (error.response?.status === 401) {
      console.warn('Erreur d\'authentification:', error.response?.data);
      const token = localStorage.getItem('monitoringAuthToken');
      console.log('Token actuel:', token);
      
      // Si le token est invalide ou expiré, déconnecter l'utilisateur
      localStorage.removeItem('monitoringAuthToken');
      window.location.href = '/monitoring/login';
    }
    
    // Gérer les erreurs serveur
    if (!error.response?.status || error.response?.status >= 500) {
      console.error('Erreur serveur:', error.response?.status, error.response?.data);
    }
    
    return Promise.reject(error);
  }
);

export default api; 