import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const [isBlogDropdownOpen, setBlogDropdownOpen] = useState(false);
  const [isMonitoringDropdownOpen, setMonitoringDropdownOpen] = useState(false);

  const handleDropdownClick = (dropdownType) => {
    if (dropdownType === 'blog') {
      setBlogDropdownOpen(!isBlogDropdownOpen);
      setMonitoringDropdownOpen(false);
    } else {
      setMonitoringDropdownOpen(!isMonitoringDropdownOpen);
      setBlogDropdownOpen(false);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/" className="navbar-logo">
          Sandbox
        </Link>
      </div>
      
      <div className="navbar-links">
        <Link to="/" className="nav-link">Home</Link>
        
        {/* Menu déroulant Blog */}
        <div className="dropdown">
          <button 
            className="dropdown-button"
            onClick={() => handleDropdownClick('blog')}
          >
            Blog <span className="dropdown-arrow">▼</span>
          </button>
          {isBlogDropdownOpen && (
            <div className="dropdown-content show">
              <Link to="/blog" className="dropdown-link" onClick={() => setBlogDropdownOpen(false)}>Blog</Link>
              <Link to="/blog-admin-panel" className="dropdown-link" onClick={() => setBlogDropdownOpen(false)}>Admin Blog</Link>
            </div>
          )}
        </div>

        {/* Menu déroulant Monitoring */}
        <div className="dropdown">
          <button 
            className="dropdown-button"
            onClick={() => handleDropdownClick('monitoring')}
          >
            Monitoring <span className="dropdown-arrow">▼</span>
          </button>
          {isMonitoringDropdownOpen && (
            <div className="dropdown-content show">
              <Link to="/monitoring/dashboard" className="dropdown-link" onClick={() => setMonitoringDropdownOpen(false)}>Dashboard Monitoring</Link>
              <Link to="/monitoring/sites" className="dropdown-link" onClick={() => setMonitoringDropdownOpen(false)}>Sites</Link>
              <Link to="/monitoring/tokens" className="dropdown-link" onClick={() => setMonitoringDropdownOpen(false)}>Gestion des Tokens</Link>
            </div>
          )}
        </div>

        <Link to="/sandbox-dashboard" className="nav-link">Dashboard Sandbox</Link>
      </div>
    </nav>
  );
};

export default Navbar;
