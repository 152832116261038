import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import '../styles/SiteDetail.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SiteDetail = () => {
  const { id } = useParams();
  const [site, setSite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSiteDetails();
  }, [id]);

  const fetchSiteDetails = async () => {
    try {
      const response = await axiosInstance.get(`/api/monitoring/sites/${id}`);
      setSite(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des détails du site:', error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteError(null);
      await axiosInstance.delete(`/api/monitoring/sites/${id}`, {
        data: { token: site.token }
      });
      navigate('/monitoring/sites', { 
        state: { message: 'Site supprimé avec succès' }
      });
    } catch (error) {
      setDeleteError(
        error.response?.data?.message || 
        'Erreur lors de la suppression du site'
      );
      setShowDeleteConfirm(false);
    }
  };

  if (loading) {
    return <div className="loading">Chargement...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!site) {
    return <div className="not-found">Site non trouvé</div>;
  }

  const responseTimeData = {
    labels: site.dailyStats.map(stat => new Date(stat.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Temps de réponse moyen (ms)',
        data: site.dailyStats.map(stat => stat.averageResponseTime),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const uptimeData = {
    labels: site.dailyStats.map(stat => new Date(stat.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Disponibilité (%)',
        data: site.dailyStats.map(stat => stat.uptime),
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        tension: 0.1
      }
    ]
  };

  const errorData = {
    labels: site.dailyStats.map(stat => new Date(stat.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Erreurs',
        data: site.dailyStats.map(stat => stat.errorCount),
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        tension: 0.1
      }
    ]
  };

  return (
    <div className="site-detail">
      <header className="site-header">
        <h1>{site.name}</h1>
        <div className={`status-badge ${site.status}`}>
          {site.status === 'up' ? 'En ligne' : site.status === 'down' ? 'Hors ligne' : 'En attente'}
        </div>
      </header>

      <div className="site-info">
        <div className="info-card">
          <h3>URL</h3>
          <p><a href={site.url} target="_blank" rel="noopener noreferrer">{site.url}</a></p>
        </div>
        <div className="info-card">
          <h3>Temps de réponse</h3>
          <p>{site.responseTime} ms</p>
        </div>
        <div className="info-card">
          <h3>Disponibilité</h3>
          <p>{site.uptime.toFixed(2)}%</p>
        </div>
        <div className="info-card">
          <h3>Dernier check</h3>
          <p>{new Date(site.lastCheck).toLocaleString()}</p>
        </div>
      </div>

      <div className="charts-grid">
        <div className="chart-container">
          <h2>Temps de réponse</h2>
          <Line data={responseTimeData} options={{
            responsive: true,
            plugins: {
              legend: { position: 'top' }
            }
          }} />
        </div>

        <div className="chart-container">
          <h2>Disponibilité</h2>
          <Line data={uptimeData} options={{
            responsive: true,
            plugins: {
              legend: { position: 'top' }
            }
          }} />
        </div>

        <div className="chart-container">
          <h2>Erreurs</h2>
          <Line data={errorData} options={{
            responsive: true,
            plugins: {
              legend: { position: 'top' }
            }
          }} />
        </div>
      </div>

      <div className="history-section">
        <h2>Historique des événements</h2>
        <div className="history-list">
          {site.statusHistory.slice(-10).reverse().map((event, index) => (
            <div key={index} className={`history-item ${event.status}`}>
              <div className="event-time">
                {new Date(event.timestamp).toLocaleString()}
              </div>
              <div className="event-status">
                {event.status === 'up' ? 'En ligne' : 'Hors ligne'}
              </div>
              <div className="event-response">
                {event.responseTime} ms
              </div>
              {event.error && (
                <div className="event-error">
                  {event.error}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {site.notifications && (
        <div className="notifications-section">
          <h2>Notifications</h2>
          <div className="notification-settings">
            <div className="notification-group">
              <h3>Email</h3>
              <label>
                <input
                  type="checkbox"
                  checked={site.notifications.email.enabled}
                  onChange={() => {/* TODO: Implémenter la mise à jour */}}
                />
                Activer les notifications par email
              </label>
              {site.notifications.email.enabled && (
                <div className="email-list">
                  {site.notifications.email.addresses.map((email, index) => (
                    <div key={index} className="email-item">
                      {email}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="notification-group">
              <h3>Webhook</h3>
              <label>
                <input
                  type="checkbox"
                  checked={site.notifications.webhook.enabled}
                  onChange={() => {/* TODO: Implémenter la mise à jour */}}
                />
                Activer le webhook
              </label>
              {site.notifications.webhook.enabled && (
                <div className="webhook-url">
                  {site.notifications.webhook.url}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="delete-section">
        <h3>Zone Dangereuse</h3>
        <p className="warning-text">
          La suppression d'un site est une action irréversible. 
          Toutes les données de monitoring seront définitivement perdues.
        </p>
        
        {deleteError && (
          <div className="error-message">
            {deleteError}
          </div>
        )}

        {!showDeleteConfirm ? (
          <button 
            className="delete-button"
            onClick={() => setShowDeleteConfirm(true)}
          >
            Supprimer ce site
          </button>
        ) : (
          <div className="delete-confirm">
            <p>Êtes-vous sûr de vouloir supprimer ce site ?</p>
            <div className="button-group">
              <button 
                className="confirm-button"
                onClick={handleDelete}
              >
                Oui, supprimer
              </button>
              <button 
                className="cancel-button"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Annuler
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteDetail; 