import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar';
import LoginAuth from './components/loginauth';
import Dashboard from './components/dashboard';
import { LoginAuthProvider } from './contexts/authcontext';
import ProtectedRoute from './components/ProtectedRoute';
import BlogList from './blog/components/BlogList';
import BlogPost from './blog/components/BlogPost';
import AdminPanel from './blog/components/AdminPanel';
import Home from './pages/home';
import './blog/styles/variables.css';
import { AuthProvider } from './blog/contexts/AuthContext';
import { LanguageBlogProvider } from './blog/translations/LanguageContext';

// Import des composants de monitoring
import MonitoringDashboard from './monitoring/components/Dashboard';
import SiteList from './monitoring/components/SiteList';
import SiteDetail from './monitoring/components/SiteDetail';
import TokenManager from './monitoring/components/TokenManager';
import MonitoringLoginForm from './monitoring/components/LoginForm';
import MonitoringAdminForm from './monitoring/components/AdminForm';
import InitialSetup from './monitoring/components/InitialSetup';
import { MonitoringAuthProvider } from './monitoring/contexts/AuthContext';
import ProtectedMonitoringRoute from './monitoring/components/ProtectedMonitoringRoute';

function AppContent() {
  return (
    <div className="App">
      <main className='main-content'>
        <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <Navbar />
              <Home />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<LoginAuth />} />
          <Route
            path="/sandbox-dashboard"
            element={
              <ProtectedRoute>
                <Navbar />
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* Routes Monitoring */}
          <Route path="/monitoring/*" element={
            <MonitoringAuthProvider>
              <Routes>
                <Route path="login" element={<MonitoringLoginForm />} />
                <Route path="register" element={<MonitoringAdminForm />} />
                <Route path="setup" element={<InitialSetup />} />
                <Route path="dashboard" element={
                  <ProtectedMonitoringRoute>
                    <Navbar />
                    <MonitoringDashboard />
                  </ProtectedMonitoringRoute>
                } />
                <Route path="sites" element={
                  <ProtectedMonitoringRoute>
                    <Navbar />
                    <SiteList />
                  </ProtectedMonitoringRoute>
                } />
                <Route path="sites/:id" element={
                  <ProtectedMonitoringRoute>
                    <Navbar />
                    <SiteDetail />
                  </ProtectedMonitoringRoute>
                } />
                <Route path="tokens" element={
                  <ProtectedMonitoringRoute>
                    <Navbar />
                    <TokenManager />
                  </ProtectedMonitoringRoute>
                } />
              </Routes>
            </MonitoringAuthProvider>
          } />

          {/* Routes Blog */}
          <Route
            path="/blog"
            element={
              <ProtectedRoute>
                <Navbar />
                <BlogList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/blog/article/:id"
            element={
              <ProtectedRoute>
                <Navbar />
                <BlogPost />
              </ProtectedRoute>
            }
          />
          <Route
            path="/blog-admin-panel"
            element={
              <ProtectedRoute>
                <Navbar />
                <AdminPanel />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
    </div>
  );
}

function App() {
  return (
    <LoginAuthProvider>
      <AuthProvider>
        <LanguageBlogProvider>
          <Router>
            <AppContent />
          </Router>
        </LanguageBlogProvider>
      </AuthProvider>
    </LoginAuthProvider>
  );
}

export default App;
