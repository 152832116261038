import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginAuth } from '../contexts/authcontext';
import './dashboard.css';

const Dashboard = () => {
    const { logout } = useLoginAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Erreur lors de la déconnexion:', error);
        }
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <h1>Dashboard Administrateur</h1>
                <button 
                    onClick={handleLogout}
                    className="logout-button"
                >
                    Se déconnecter
                </button>
            </div>
            <div className="dashboard-content">
                {/* Contenu du dashboard */}
            </div>
        </div>
    );
};

export default Dashboard;
