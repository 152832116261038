import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMonitoringAuth } from '../contexts/AuthContext';
import { useLoginAuth } from '../../contexts/authcontext';
import '../styles/Auth.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login, hasAdmin } = useMonitoringAuth();
  const globalAuth = useLoginAuth();

  useEffect(() => {
    // Dans le contexte sandbox uniquement, vérifier l'auth globale
    if (process.env.REACT_APP_IS_SANDBOX === 'true' && !globalAuth?.token) {
      navigate('/login');
      return;
    }

    // Rediriger vers la configuration initiale s'il n'y a pas d'admin
    if (hasAdmin === false) {
      navigate('/monitoring/setup');
    }
  }, [hasAdmin, globalAuth, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await login(email, password);
      navigate('/monitoring/dashboard');
    } catch (error) {
      setError(
        error.response?.data?.message || 
        'Une erreur est survenue lors de la connexion'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Si on n'a pas encore vérifié s'il y a un admin ou si on est dans le sandbox et pas connecté globalement
  if (hasAdmin === null || (process.env.REACT_APP_IS_SANDBOX === 'true' && !globalAuth?.token)) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="auth-container">
      <div className="auth-form-container">
        <h2>Connexion Monitoring</h2>
        <p className="setup-info">
          Connectez-vous au module de monitoring pour gérer vos sites.
        </p>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="votre@email.com"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Votre mot de passe"
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button 
            type="submit" 
            className="submit-button"
            disabled={isLoading}
          >
            {isLoading ? 'Connexion...' : 'Se connecter'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm; 