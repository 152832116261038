import React, { createContext, useContext, useState } from 'react';
import axios from '../utils/axios';
import logger from '../utils/logger';

const LoginAuthContext = createContext(null);

export const LoginAuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const login = async (email, password) => {
        try {
            const response = await axios.post('/api/auth/login', { email, password });
            const { token } = response.data;
            
            localStorage.setItem('token', token);
            setToken(token);
            
            return response.data;
        } catch (error) {
            logger.error('Login error:', error);
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setToken(null);
        delete axios.defaults.headers.common['Authorization'];
    };

    return (
        <LoginAuthContext.Provider value={{ token, login, logout }}>
            {children}
        </LoginAuthContext.Provider>
    );
};

export const useLoginAuth = () => {
    const context = useContext(LoginAuthContext);
    if (!context) {
        throw new Error('useLoginAuth must be used within an LoginAuthProvider');
    }
    return context;
};