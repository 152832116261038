import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { Line, Pie } from 'react-chartjs-2';
import TokenManager from './TokenManager';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import '../styles/Dashboard.css';
import { useMonitoringAuth } from '../contexts/AuthContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const { logout } = useMonitoringAuth();
  const [stats, setStats] = useState({
    totalSites: 0,
    sitesUp: 0,
    sitesDown: 0,
    averageUptime: 0,
    totalErrors: 0,
    dailyStats: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      setError(null);
      const response = await axiosInstance.get('/api/monitoring/stats');
      setStats(response.data);
      setRetryCount(0); // Réinitialiser le compteur en cas de succès
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
      if (error.code === 'ECONNABORTED') {
        setError('La connexion au serveur a échoué. Tentative de reconnexion...');
        if (retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
            fetchStats();
          }, 2000 * (retryCount + 1)); // Délai progressif
        } else {
          setError('Impossible de se connecter au serveur. Veuillez réessayer plus tard.');
        }
      } else {
        setError('Erreur lors de la récupération des statistiques. ' + 
          (error.response?.data?.message || error.message));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const uptimeChartData = {
    labels: stats.dailyStats.map(stat => new Date(stat.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Disponibilité moyenne (%)',
        data: stats.dailyStats.map(stat => stat.uptime),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const errorChartData = {
    labels: stats.dailyStats.map(stat => new Date(stat.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Nombre d\'erreurs',
        data: stats.dailyStats.map(stat => stat.errorCount),
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        tension: 0.1
      }
    ]
  };

  const statusDistributionData = {
    labels: ['En ligne', 'Hors ligne'],
    datasets: [
      {
        data: [stats.sitesUp, stats.sitesDown],
        backgroundColor: ['rgb(75, 192, 192)', 'rgb(255, 99, 132)']
      }
    ]
  };

  if (isLoading) {
    return <div className="dashboard loading">Chargement...</div>;
  }

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Tableau de Bord</h1>
        <button 
          className="logout-button"
          onClick={logout}
        >
          Se déconnecter
        </button>
      </div>
      
      <TokenManager />
      
      {error && (
        <div className="error-message">
          {error}
          <button 
            className="retry-button"
            onClick={() => {
              setRetryCount(0);
              fetchStats();
            }}
          >
            Réessayer
          </button>
        </div>
      )}

      <div className="stats-overview">
        <div className="stat-card">
          <h3>Sites Totaux</h3>
          <p>{stats.totalSites}</p>
        </div>
        <div className="stat-card">
          <h3>Sites En Ligne</h3>
          <p className="up">{stats.sitesUp}</p>
        </div>
        <div className="stat-card">
          <h3>Sites Hors Ligne</h3>
          <p className="down">{stats.sitesDown}</p>
        </div>
        <div className="stat-card">
          <h3>Disponibilité Moyenne</h3>
          <p>{stats.averageUptime.toFixed(2)}%</p>
        </div>
      </div>

      <div className="charts-container">
        <div className="chart">
          <h2>Disponibilité sur 30 jours</h2>
          <Line data={uptimeChartData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: false
              }
            }
          }} />
        </div>

        <div className="chart">
          <h2>Erreurs sur 30 jours</h2>
          <Line data={errorChartData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: false
              }
            }
          }} />
        </div>

        <div className="chart">
          <h2>Distribution des Statuts</h2>
          <Pie data={statusDistributionData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              }
            }
          }} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 