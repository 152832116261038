import React, { useState, useEffect } from 'react';
import { useMonitoringAuth } from '../contexts/AuthContext';
import '../styles/TokenManager.css';

const TokenManager = () => {
  const { token: authToken, api: axiosInstance } = useMonitoringAuth();
  const [token, setToken] = useState('');
  const [isTokenVisible, setIsTokenVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (authToken) {
      fetchToken();
    } else {
      setIsLoading(false);
    }
  }, [authToken]);

  const fetchToken = async () => {
    try {
      setError(null);
      const response = await axiosInstance.get('/api/monitoring/token');
      setToken(response.data.token);
      setRetryCount(0);
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        setError('La connexion au serveur a échoué. Tentative de reconnexion...');
        if (retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
            fetchToken();
          }, 2000 * (retryCount + 1));
        } else {
          setError('Impossible de se connecter au serveur. Veuillez réessayer plus tard.');
        }
      } else {
        setError('Erreur lors de la récupération du token. ' + 
          (error.response?.data?.message || error.message));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const generateNewToken = async () => {
    if (!window.confirm('Êtes-vous sûr de vouloir générer un nouveau token ? Les sites existants devront être mis à jour avec le nouveau token.')) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const response = await axiosInstance.post('/api/monitoring/token/generate');
      setToken(response.data.token);
      setIsTokenVisible(true);
      setRetryCount(0);
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        setError('La connexion au serveur a échoué lors de la génération du token.');
      } else if (error.response?.status === 401) {
        setError('Vous devez être connecté pour générer un nouveau token');
      } else {
        setError('Erreur lors de la génération du token. ' + 
          (error.response?.data?.message || error.message));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleTokenVisibility = () => {
    setIsTokenVisible(!isTokenVisible);
  };

  const copyToken = async () => {
    try {
      await navigator.clipboard.writeText(token);
      const originalError = error;
      setError('Token copié avec succès !');
      setTimeout(() => {
        setError(originalError);
      }, 2000);
    } catch (error) {
      setError('Erreur lors de la copie du token');
    }
  };

  if (!authToken) {
    return null;
  }

  if (isLoading) {
    return <div className="token-manager loading">Chargement...</div>;
  }

  return (
    <div className="token-manager">
      <div className="token-header">
        <h2>Token de Monitoring</h2>
        <button 
          className="generate-btn"
          onClick={generateNewToken}
          disabled={isLoading || !authToken}
        >
          Générer un nouveau token
        </button>
      </div>

      <div className="token-display">
        <div className="token-field">
          <label>Votre token :</label>
          <div className="token-value">
            {isTokenVisible ? (
              <code>{token || '(Aucun token généré)'}</code>
            ) : (
              <code>••••••••••••••••••••••••••</code>
            )}
            <button 
              className="visibility-btn"
              onClick={toggleTokenVisibility}
              title={isTokenVisible ? "Masquer le token" : "Afficher le token"}
              disabled={!token}
            >
              {isTokenVisible ? "🙈" : "👁️"}
            </button>
            <button 
              className="copy-btn"
              onClick={copyToken}
              title="Copier le token"
              disabled={!token}
            >
              📋
            </button>
          </div>
        </div>

        {error && (
          <div className={`message ${error.includes('succès') ? 'success' : 'error'}`}>
            {error}
          </div>
        )}

        <div className="token-info">
          <h3>Comment utiliser ce token ?</h3>
          <ol>
            <li>Créez un fichier <code>.well-known/monitoring-allowed</code> sur le site à monitorer</li>
            <li>Ajoutez cette ligne dans le fichier :</li>
            <pre>MONITOR_TOKEN={isTokenVisible ? token : "votre_token"}</pre>
            <li>Assurez-vous que le fichier est accessible via :</li>
            <pre>https://votre-site.com/.well-known/monitoring-allowed</pre>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TokenManager; 