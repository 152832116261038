import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMonitoringAuth } from '../contexts/AuthContext';
import { useLoginAuth } from '../../contexts/authcontext';
import '../styles/Auth.css';

const InitialSetup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { register, hasAdmin } = useMonitoringAuth();
  const globalAuth = useLoginAuth();

  useEffect(() => {
    // Dans le contexte sandbox uniquement, vérifier l'auth globale
    if (process.env.REACT_APP_IS_SANDBOX === 'true' && !globalAuth?.token) {
      navigate('/login');
      return;
    }

    // Rediriger vers le dashboard s'il y a déjà un admin
    if (hasAdmin === true) {
      navigate('/monitoring/dashboard');
    }
  }, [hasAdmin, globalAuth, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      setIsLoading(false);
      return;
    }

    try {
      await register(email, password);
      navigate('/monitoring-dashboard');
    } catch (error) {
      setError(
        error.response?.data?.message || 
        'Une erreur est survenue lors de la création du compte'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Si on n'a pas encore vérifié s'il y a un admin ou si on est dans le sandbox et pas connecté globalement
  if (hasAdmin === null || (process.env.REACT_APP_IS_SANDBOX === 'true' && !globalAuth?.token)) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="auth-container initial-setup">
      <div className="auth-form-container">
        <h2>Configuration Initiale du Monitoring</h2>
        <p className="setup-info">
          Bienvenue dans la configuration initiale du système de monitoring.
          Veuillez créer votre compte administrateur principal.
        </p>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="email">Email de l'administrateur</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="admin@votre-domaine.com"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Choisissez un mot de passe sécurisé"
              minLength="8"
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirmez votre mot de passe"
              minLength="8"
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button 
            type="submit" 
            className="submit-button"
            disabled={isLoading}
          >
            {isLoading ? 'Création...' : 'Créer le compte administrateur'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default InitialSetup; 